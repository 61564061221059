/**
 * 全局配置文件
 */
import { env } from './env';
export default {
    /*  mqtt链接配置
     */
    mqttMy: env.NODE_ENV === 'development' ? 'ws://ai-demo.wangdatao.com/mqtt' : 'ws://ai-demo.wangdatao.com/mqtt',

    //微信pc扫码登录
    redirect_url: env.NODE_ENV === 'development' ? 'http://www.mj.ink' : 'http://www.mj.ink',
    // 微信开放平台  网站应用 appid
    wxAppid: 'wx',

    key: 'uni-chatgpt-web', //配置主键,目前用于存储
    tokenHeader: 'Blade-Auth',
    //接口访问服务头部
    Authorization: 'c2FiZXI6c2FiZXJfc2VjcmV0',
    apiRequestHead: env.NODE_ENV == 'development' ? 'mjkj-chat' : 'mjkj-chat',
    mqttUserName: 'MJKJ_CHATGPT', // rabbitmq连接用户名
    mqttPassword: 'MJKJ_CHATGPT@QWER', // rabbitmq连接密码
}